<template>
  <base-layout>
    <div class="block full game">
      <div class="block-title themed-background">
        <h2>Game</h2>
      </div>
      <div class="block-content">
        <ul class="nav nav-game">
          <li
            v-for="list in Lists.data.category"
            :key="list.key"
            :class="{ active: list.key == activeTab }"
            v-on:click="tabGameControl(list.key)"
          >
            <a href="javascript:void(0)">{{ list.name }}</a>
          </li>
        </ul>
        <hr class="line-bottom" />
        <div class="list">
          <div v-for="game in Lists.data.game[activeTab]" :key="game.game_name" class="game">
            <div class="card-game">
              <img :src="game.game_image_url" />
              <div class="title">
                <a :href="game.game_play" target="_blank"
                  >{{ game.game_display_name
                  }}<span class="btn-play"
                    >Play <i class="fa fa-angle-double-right" aria-hidden="true"></i></span
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "./layouts/Base";
import { mapGetters } from "vuex";
export default {
  name: "Game",
  components: {
    BaseLayout
  },
  data: () => {
    const tabItems = [
      { name: "list", value: "Member list" },
      { name: "add", value: "Add new member" },
      { name: "tree", value: "Member tree" }
    ];
    return {
      tabs: tabItems,
      activeTab: "all"
    };
  },
  methods: {
    tabGameControl: function(key) {
      this.activeTab = key;
    }
  },
  computed: {
    ...mapGetters({
      Lists: "game/List"
    })
  },
  created() {
    this.$store.dispatch("game/req_getListGame");
  }
};
</script>
<style scoped>
.block.game {
  background: rgb(255 255 255/40%);
  border: none;
  padding: 0;
  border-radius: 10px;
}
.line-bottom {
  border-bottom: 2px #d8a342 solid;
  border-top: none;
  width: 35%;
  min-width: 200px;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.block.game .block-title {
  margin: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0;
}
.block.game .block-title h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}
.block.game .block-content {
  padding: 15px 25px;
}
.nav-game {
  background: #fff;
  width: fit-content;
  border-radius: 5px;
}
.nav-game li {
  display: inline-block;
  border-right: 1px #d8a34296 solid;
  margin: 10px 0;
  padding: 0 20px;
}
.nav-game li:last-child {
  border: none;
}
.nav-game li a {
  padding: 0;
  color: #777;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.3s;
}
.nav-game li.active a,
.nav-game li:hover a {
  color: #d8a342;
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.list .game {
  width: 25%;
  padding: 10px;
}
.list .game .card-game {
  background: #fff;
  box-shadow: 3px 3px 5px rgb(0 0 0 / 40%);
  border-radius: 10px;
  min-height: 120px;
  overflow: hidden;
  position: relative;
}
.list .game .card-game img {
  object-fit: cover;
  width: 100%;
  transition: 0.3s;
}
.list .game .card-game:hover img {
  transform: scale(1.1);
}
.list .game .card-game .title {
  position: absolute;
  bottom: 0;
  left: 0%;
  right: 0;
  background: rgb(0 0 0 / 40%);
  padding: 10px;
  text-align: center;
}
.list .game .card-game .title a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.3s;
  text-decoration: none;
}

.list .game .card-game .title a::after {
  background: #d8a342;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  height: 0;
  transition: 0.3s;
  z-index: 1;
  content: "";
  position: absolute;
}
.list .game .card-game:hover .title a::after {
  height: 100%;
}
.list .game .card-game .title a span.btn-play {
  position: absolute;
  left: -100%;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: 0.3s;
  transition-delay: 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list .game .card-game .title a span.btn-play i {
  font-size: 16px;
  padding-left: 5px;
}
.list .game .card-game:hover .title a span.btn-play {
  left: 0;
}
@media only screen and (max-width: 992px) {
  .list .game {
    width: 33.3333%;
  }
}
@media only screen and (max-width: 767px) {
  .list .game {
    width: 50%;
  }
}
@media only screen and (max-width: 575px) {
  .list .game {
    padding: 5px;
  }
  .list {
    margin: 0 -5px;
  }
  .block.game .block-content {
    padding: 15px 10px;
  }
  .nav-game li {
    margin: 5px 0;
    padding: 0 10px;
  }
  .list .game .card-game .title a span {
    font-size: 12px;
  }
}
</style>
